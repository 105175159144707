<template>
  <ul v-if="totalPages > 1" class="pagination">
    <li v-if="value > 1" role="presentation" aria-hidden="true" class="page-item">
      <button type="button" class="page-link" @click="changePage(value - 1)">
        <span class="material-symbols-outlined">chevron_left</span>
      </button>
    </li>
    <li v-for="page in pages" :key="page" class="page-item" :class="{ active: value == page }">
      <button v-if="page != '...'" type="button" class="page-link" @click="changePage(page)">{{
          page
        }}
      </button>
      <span v-else class="page-link">…</span>
    </li>
    <li v-if="value < totalPages" role="presentation" aria-hidden="true" class="page-item">
      <button type="button" class="page-link" @click="changePage(value + 1)">
        <span class="material-symbols-outlined">chevron_right</span>
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PaginationComponent',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    totalPages: {
      type: [String, Number],
      default: '1',
    },
  },
  computed: {
    startPage() {
      if (this.value >= 4 && this.value < this.totalPages) {
        return this.value - 1;
      }
      return Math.max(1, this.value - 2);
    },
    endPage() {
      if (this.value < this.totalPages - 2 && this.value > 1) {
        return this.value + 1;
      }
      return Math.min(this.totalPages, this.value + 2);
    },
    pages() {
      const range = [];

      if (this.value >= 4) {
        range.push(1);
        if (this.totalPages > 4) range.push('...');
      }

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push(i);
      }

      if (this.value < this.totalPages - 2) {
        if (this.totalPages > 4) range.push('...');
        range.push(this.totalPages);
      }

      return range;
    },
  },
  methods: {
    changePage(page) {
      this.$emit('change', page);
      this.$emit('input', page);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  margin-top: 68px;
  justify-content: flex-end;

  .page-item {
    height: 23px;
    width: 23px;

    &.active {
      background-color: var(--clr-yup-purple);
      border-radius: 50%;
      .page-link {
        color: white;
      }
    }

    .page-link {
      width: 100%  !important;
      text-align: center;
      span {
        font-size: 37px;
        line-height: 0.7;
        padding: 0;
      }
    }
  }
}
</style>
