<template>
  <div class="content">
    <PageHeader :title="$t('generic-str.menu.customers')" />
    <section class="page-content container-fluid">
      <div class="row">
        <div :class="showCustomerEdit ? 'col-md-8 col-sm-12' : 'col-12'">
          <admin-customers-card ref="plans" @toggle-customer-edit="toggleCustomerEdit" />
        </div>
        <Transition name="slide-fade">
          <div v-show="showCustomerEdit" :class="{'col-md-4 col-sm-12': showCustomerEdit}">
            <admin-customer-toggle @close-customer-edit="closeCustomerEdit" :customer="customerEditData" :singleCustomer="customer" />
          </div>
        </Transition>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Swal from 'sweetalert2';
import CustomerService from '@/services/customer.service';
import PageHeader from '@/components/PageHeader.vue';
import AdminCustomersCard from '@/components/cards/AdminCustomersCard.vue';
import AdminCustomerToggle from '@/components/cards/AdminCustomerToggle.vue';

export default {
  name: 'Customers',
  components: {
    PageHeader,
    AdminCustomersCard,
    AdminCustomerToggle,
  },
  data() {
    return {
      showCustomerEdit: false,
      customerEditCard: 'col-4',
      customerEditData: {},
      fetched: false,
      customers: [],
      customer: [],
      searchValue: '',
      form: {
        name: '',
        tax_id: '',
        page: 1,
      },
      pages: 1,
      isSending: false,
      selectedCustomers: [],
    };
  },
  created() {
    this.fetch(1);
  },
  methods: {
    toggleCustomerEdit(item) {
      /* this.showCustomerEdit = !this.showCustomerEdit;
      this.customerEditData = item;
      CustomerService.getCustomer(this.customerEditData.id).then(
        (response) => {
          this.customer = response;
        },
        (error) => {
          console.log('Request error:', error);
        },
      ); */
    },
    closeCustomerEdit() {
      this.showCustomerEdit = false;
    },
    fetch(page) {
      const search = this.searchValue.trim();
      // Vanilla javascript regex -> search.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
      const taxID = search.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5',
      );
      const isTaxID = taxID !== search && !Number.isNaN(parseInt(taxID, 10));
      this.form.page = page;
      this.fetched = false;
      if (isTaxID || this.searchValue.length === 18) {
        this.form.name = '';
        this.form.tax_id = taxID;
      } else {
        this.form.tax_id = '';
        this.form.name = search;
      }
      CustomerService.getCustomers(this.form).then(
        (response) => {
          this.fetched = true;
          this.customers = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    remove(id) {
      Swal.fire({
        title: this.$t('adm.customer.remove'),
        text: this.$t('adm.customer.remove-text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          this.fetched = false;
          CustomerService.removeCustomer(id).then(
            () => {
              this.fetch(this.form.page);
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('webhook-component.remove.deleted'),
                type: 'success',
              });
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
td a {
  color: inherit;
}
.block-el {
  min-height: 300px;
}
.btn-remove {
  color: #fff;
  margin-bottom: 5px;
}
.btn-new {
  margin-bottom: 5px;
}
.color-white {
  color: #fff;
}
.not-found a {
  color: var(--gray);
}
.form-search {
  float: right;
  width: 100%;
}
.form-search button {
  border: solid 1px #ccc;
  border-left: 0px;
  text-align: center;
  border-radius: 0px 10px 10px 0px;
  height: 35px;
}
.form-search button i {
  font-size: 16px;
}
.w-100 {
  width: 100% !important;
}

.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
