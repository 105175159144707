<template>
	<card>
		<template #header>
			<i @click="$emit('close-customer-edit')" class="dripicons-cross"></i>
		</template>
		<template #body>
			<h2 class="customer-title">{{customer.name}}</h2>
			<section class="tab-header">
				<ul>
					<li @click="showInfoTab"><i class="dripicons-information pointer"></i></li>
					<li @click="showProductTab"><i class="dripicons-cart pointer"></i></li>
					<li @click="showShortCodeTab"><i class="dripicons-code pointer"></i></li>
					<li><router-link :to="`/billing`"><i class="dripicons-document pointer"></i></router-link></li>
				</ul>
			</section>
			<div v-show="infoTab" class="company-info">
				<section class="general-info">
					<div class="tab-title">
						<p>{{ $t('generic-str.general-info') }}</p>
						<router-link
                        :to="`/admin/customers/${customer.id}`"
                        >
                          <i class="dripicons-document-edit"></i>
						</router-link>
					</div>
					<ul>
						<li><i class="dripicons-store"></i> {{customer.name}}</li>
						<li><i class="dripicons-user-id"></i> {{customer.tax_id}}</li>
						<li><i class="dripicons-mail"></i> {{customer.billing_email}}</li>
						<li><i class="dripicons-lock"></i> {{customer.antifraud}}</li>
						<li><i class="dripicons-location"></i> {{customer.street}}, {{ customer.city }} - {{ customer.province }} </li>
					</ul>
				</section>
				<section class="contract">
					<p>{{ $t('generic-str.contract') }}</p>
					<ul>
						<li><i class="dripicons-wallet"></i> {{customer.balance}}</li>
						<li><i class="dripicons-graph-line"></i> {{ customer.currency }}</li>
						<li v-if="customer.type == 'monthly'"><i class="dripicons-card"></i> Mensal</li>
						<li v-if="customer.type == 'test'"><i class="dripicons-card"></i> Teste</li>
						<li v-if="customer.type == 'prepaid'"><i class="dripicons-card"></i> Pré pago</li>
						<li v-if="customer.type == 'weekly'"><i class="dripicons-card"></i> Semanal</li>
						<li v-if="customer.type == 'biweekly'"><i class="dripicons-card"></i> Quinzenal</li>
						<li><i class="dripicons-calendar"></i> {{customer.due_date}}</li>
					</ul>
				</section>

				<section class="responsible">
					<p>{{ $t('generic-str.responsible') }}</p>
					<div v-for="owner in singleCustomer.owners" :key="owner.id" class="card card-contacts relative no-margin">
						<div class="row card-contacts-list-item relative padding-14">
							<div class="col-sm-3">
								<div class="card-contacts-list-avatar">
								<span>{{ owner.name.charAt(0) }}</span>
								</div>
							</div>
							<div class="col-sm-9 card-contacts-list-item-body">
								<h3>{{ owner.name }}</h3>
								<h2 class="hide"></h2>
								<div class="card-contacts-list-item-body-info">
								<div>
									<span class="material-symbols-outlined to-left icon-contact">call</span> <span class="to-left">{{ owner.mobile_number }}</span>
									<div style="clear: both;"></div>
								</div>
								<div>
									<span class="material-symbols-outlined to-left icon-contact">mail</span> <span class="to-left">{{ owner.email }}</span>
									<div style="clear: both;"></div>
								</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<div v-show="productTab">
				<div class="create-product">
					<router-link
                      :to="`/admin/customers/${customer.id}/products/create`"
                    >
                      <button
                          type="button"
                          class="btn btn-yup-purple"
                      >
                      {{ $t('customer-component.add-product') }}
                      </button>
                    </router-link>
                </div>
				<section class="products-info">
					<p>{{ $t('app.product').split(' | ')[1] }}</p>
					<div v-if="!fetchedProducts" class="loading min-h-300">
						<LoadingAnim />
					</div>
					<div v-for="product in products" :key="product.id" class="product-card">
						<ul>
							<li><i class="dripicons-phone"></i> {{ product.name }} </li>
							<li><i class="dripicons-wallet"></i> {{ product.plan.credits }}</li>
							<li><i class="dripicons-calendar"></i> {{ product.created_at | date('DD/MM/YYYY HH:mm:ss') }}</li>
						</ul>
						<router-link
							:to="`/admin/customers/${customer.id}/products/${product.id}`"
						>
						<i class="dripicons-document-edit"></i>
						</router-link>
					</div>
				</section>
			</div>

			<div v-show="shortCodeTab">
				<div class="create-product">
					<router-link
                      :to="`/admin/customers/${customer.id}/short-codes/create`"
                    >
                      <button
                          type="button"
                          class="btn btn-yup-purple"
                      >
                      {{ $t('sms.short-code.add') }}
                      </button>
                    </router-link>
                </div>
				<section class="products-info shortcode-info">
					<p>{{ $t('sms.short-code.info') }}</p>
					<div v-if="!fetchedShortCodes" class="loading min-h-300">
						<LoadingAnim />
					</div>
					<div v-for="shortcode in shortCodes" :key="shortcode.id" class="product-card">
						<ul>
							<li><i class="dripicons-code"></i> {{ shortcode.name }}</li>
							<li><i class="dripicons-calendar"></i> {{ shortcode.created_at | date('DD/MM/YYYY HH:mm:ss') }}</li>
						</ul>
						<router-link
							:to="`/admin/customers/${customer.id}/short-codes/${shortcode.id}`"
						>
						<i class="dripicons-document-edit"></i>
						</router-link>
					</div>
				</section>
			</div>
		</template>
	</card>
</template>

<script>
import Card from '@/components/Card.vue';
import CustomerService from '@/services/customer.service';
import SmsService from '@/services/admin/sms.service';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';

export default {
  name: 'AdminCustomerEdit',
  components: {
    Card,
    LoadingAnim,
  },
  props: {
    customer: Object,
    singleCustomer: {},
  },
  emits: ['close-customer-edit'],
  data() {
    return {
      infoTab: true,
      productTab: false,
      shortCodeTab: false,
      products: {},
      shortCodes: {},
      fetchedProducts: false,
      fetchedShortCodes: false,
    };
  },
  methods: {
    showProductTab() {
      this.productTab = true;
      this.infoTab = false;
      this.shortCodeTab = false;
      this.fetchProducts();
    },
    showInfoTab() {
      this.productTab = false;
      this.infoTab = true;
      this.shortCodeTab = false;
    },
    showShortCodeTab() {
      this.productTab = false;
      this.infoTab = false;
      this.shortCodeTab = true;
      this.fetchShortCodes();
    },
    fetchProducts() {
      this.$store
        .dispatch('fetchAdminProducts', {
          account_id: this.customer.id,
          page: 1,
        })
        .then((response) => {
          this.products = response.data;
          this.fetchedProducts = true;
        });
    },
    fetchShortCodes() {
      SmsService.getShortCodes({
        account_id: this.customer.id,
      })
        .then((response) => {
          this.shortCodes = response;
          this.fetchedShortCodes = true;
        });
      this.addOverflow();
    },
    addOverflow() {
      if (document.getElementsByClassName('product-card').length > 8) {
        console.log('Overflow ativado');
        document.querySelector('.shortcode-info').classList.add('overflow-vert');//	Adiciona overflow scroll caso tenha muitos elementos.
      }
    },
  },
};
</script>

<style lang="scss" scope>

	.customer-title{
		text-align: center;
	}

	.tab-header ul{
		list-style: none;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding-left: 0;
	}

	.contract ul {
		list-style: none;
		text-align: center;
		padding-left: 0;
		display: flex;
		flex-direction: row;
	}

	.tab-header ul li, .contract ul li {
		padding: 1em 1em;
	}

	.tab-header ul li i {
		font-size: 1.5em;
		color: #752DE6;
	}

	.contract ul li i {
		font-size: 1.5em;
		color: #752DE6;
		vertical-align: middle;
		margin-right: 5px;
	}

	.general-info ul{
		list-style: none;
		display: flex;
		flex-direction: column;
		padding-left: 0;
	}

	.general-info ul li{
		padding: 0.5em;
	}

	.general-info ul li i {
		font-size: 1.5em;
		color: #752DE6;
		vertical-align: middle;
		margin-right: 5px;
	}

	.card-contacts {
		margin: 18px;
	}
	.card-contacts-list-item {
		padding: 20px;
	}
	.card-contacts-list-avatar {
		background: #C02FDF;
		width: 65px;
		height: 65px;
		border-radius: 55px;
		text-align: center;
		vertical-align:middle;
		margin: 0 auto;
	}
	.card-contacts-list-avatar span {
		font-size: 25px;
		color: white;
		display: block;
		padding-top: 15px;
	}
	.card-contacts-list-item h3 {
		color: #752DE6;
		font-size: 14px;
	}
	.card-contacts-list-item h2 {
		color: #9CA7B0;
		font-size: 14px;
	}
	.card-contacts-list-item-body span{
		color: #9CA7B0;
		font-size: 13px;
		display: block;
		margin-top: 5px;
		font-weight: 300;
	}
	.card-contacts-list-item-body-info {
		font-size: 13px;
		color: #9CA7B0;
		margin-top: 10px;
	}

	.to-left {
		float: left !important;
	}

	.icon-contact {
		font-size: 18px !important;
		margin-right: 5px !important;
		margin-top: 6px !important;
	}

	.card-header .dripicons-cross {
		margin: 0 95%;
		cursor: pointer;
	}

	.pointer {
		cursor: pointer;
	}

	.products-info ul {
		list-style: none;
		display: flex;
		flex-direction: column;
		padding-left: 0;
	}

	.products-info ul li{
		padding: 5px;
	}

	.products-info ul li i {
		font-size: 1.2em;
		color: #752DE6;
		vertical-align: middle;
		margin-right: 5px;
	}

	.product-card {
		display: flex;
		justify-content: space-between;
		border-bottom: 2px solid gainsboro;
		margin-bottom: 1em;
	}

	.product-card i {
		vertical-align: middle;
	}

	.create-product {
       display: flex;
       justify-content: center;
       margin: 1em;
	}

	.tab-title {
       display: flex;
       justify-content: space-between;
	}

	.no-margin {
		margin: 0 !important;
	}

	.padding-14 {
		padding: 14px !important;
	}

	.overflow-vert {
		overflow-y: scroll;
		max-height: 30.5em
	}
</style>
