<template>
  <card
    ref="card"
    actions
    block
    :title="$tc('app.customer', 2)"
    @reload="fetchCustomers"
    :filters="filters"
    @filter="applyFilter"
  >
    <template #actions>
      <li>
        <router-link :to="{ name: 'CreateCustomer' }">
          <i class="dripicons-plus"></i>
        </router-link>
      </li>
    </template>
    <template #body>
      <TableComponent
        :fields="fields"
        :items="plans"
        :busy="loading"
        :clickable="clickable"
        @row-click="toggleCustomerEdit"
      >
        <template #cell(protocol)="slotProps">
          <span class="protocol-cell">
            {{ slotProps.data | formatDate }}
          </span>
        </template>
        <template #cell(status)="slotProps">
          <BadgeComponent variant="success" v-if="slotProps.data == 'active'">Ativo</BadgeComponent>
          <BadgeComponent variant="danger" v-else-if="slotProps.data == 'inactive'">Inativo</BadgeComponent>
          <BadgeComponent variant="danger" v-else-if="slotProps.data == 'suspended'">Suspenso</BadgeComponent>
        </template>
        <template #cell(actions)="slotProps">
          <dropdown>
            <template v-slot:text>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <template v-slot:items>
              <router-link
                :to="{ name: 'CustomerEdit', params: { customerId: slotProps.item.id } }"
                class="dropdown-item"
                >{{ $t('app.edit') }}</router-link
              >
              <a
                role="button"
                v-if="allowedToDestroy(slotProps.item.id)"
                class="dropdown-item"
                @click="destroy(slotProps.item.id)"
                >{{ $t('app.delete') }}</a
              >
            </template>
          </dropdown>
        </template>
      </TableComponent>
      <pagination :totalPages="pages" v-model="currentPage" @change="fetchCustomers" />
      <modal
        ref="plan-modal"
        :title="$t('generic-str.new-plan')"
        @submit="store"
      >
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">
            {{ $t('name') }}
          </label>
          <div class="col-sm-10">
            <input type="text" class="form-control" v-model="form.name" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">
            {{ $t('type') }}
          </label>
          <div class="col-sm-10">
            <select class="form-control" v-model="form.service" required>
              <option value="sms" selected>SMS</option>
              <!-- <option value="omni">Omni Business</option> -->
              <option value="omni">Chat</option>
              <option value="whatsapp">Whatsapp API</option>
              <option value="rcs">Google RCS</option>
              <option value="email">E-Mail</option>
              <option value="lookup">Lookup</option>
            </select>
          </div>
        </div>
      </modal>
    </template>
  </card>
</template>

<script>
// @ is an alias to /src
import Swal from 'sweetalert2';
import Card from '@/components/Card.vue';
import Dropdown from '@/components/Dropdown.vue';
import Modal from '@/components/Modal.vue';
import TableComponent from '@/components/TableComponent.vue';
import Pagination from '@/components/PaginationComponent.vue';
import BadgeComponent from '@/components/ui/BadgeComponent.vue';
import moment from 'moment-timezone';

export default {
  name: 'AdminPlansCard',
  components: {
    BadgeComponent,
    TableComponent,
    Card,
    Dropdown,
    Modal,
    Pagination,
  },
  data() {
    return {
      fields: [
        'name',
        'tax_id',
        'status',
        { key: 'updated_at', formatter: (value) => moment(value).format('DD/MM/YYYY HH:mm:ss') },
        'actions',
      ],
      filters: [
        {
          name: 'name',
          type: 'text',
          value: '',
        },
        {
          name: 'tax_id',
          type: 'text',
          value: '',
        },
      ],
      loading: true,
      plans: [],
      currentPage: 1,
      pages: 1,
      form: {},
      clickable: true,
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetchCustomers();
  },
  methods: {
    toggleCustomerEdit(item) {
      this.$emit('toggle-customer-edit', item);
    },
    fetchCustomers(page = this.currentPage) {
      this.page = page;
      this.loading = true;
      this.$store
        .dispatch('fetchAdminCustomers', { page, ...this.form })
        .then((response) => {
          this.plans = response.data;
          this.pages = response.last_page;
          this.loading = false;
        });
    },
    allowedToDestroy(customerId) {
      return (
        this.$store.state.auth.user.role === 'owner' &&
        this.$store.state.account.id !== customerId
      );
    },
    applyFilter(filters) {
      this.form = filters;
      this.fetchCustomers(1);
    },
    store() {
      this.loading = true;
      this.$store.dispatch('storeAdminPlans', this.form).then((response) => {
        this.fetch();
      });
    },
    update(planId) {
      this.loading = true;
      this.$store
        .dispatch('updateAdminPlans', {
          planId,
          input: this.form,
        })
        .then((response) => {
          this.fetch();
        });
    },
    destroy(customerId) {
      this.loading = true;
      Swal.fire({
        title: this.$t('adm.customer.remove'),
        text: this.$t('adm.customer.remove-text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('destroyCustomer', customerId)
            .then((response) => {
              this.fetchCustomers(1);
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
